import { Dropdown, DropdownOptions, initFlowbite } from "flowbite";
import { useCallback, useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "react-query";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { Get, IApiResult, PostData } from "../common/request";
import { useSearchContext } from "../common/useSearchContext";
import { setSearchHistory, getSearchHistory, clearSearchHistory, removeSearchHistory } from "../common/searchHistory";
import { BaseUrl, ListView, LoadingView, NoDataView, ProTags } from "../common/global";
import { PaginationView } from "../common/pagination";
import { ProItemClick } from "../common/detailTransition";

export const Search = () => {
  const navigate = useNavigate();

  const [setHeaderTitle] = useOutletContext() as any;
  const { key = '', page = 0 } = useParams();

  const [refresh, setRefresh] = useState(false);

  const inputRef = useRef<any>(null);
  const dropdownDomRef = useRef<any>(null);

  const { dropdown, focusHandler, inputHandler, keyDownHandler, searchVal, setSearchVal, selectIndex, setSelectIndex, tipDatas } = useSearchContext(inputRef, dropdownDomRef);

  useEffect(() => {
    // initFlowbite();
    setHeaderTitle('搜索');
    setSearchVal(key);
  }, []);

  const searchTipClick = (tipTitle: string) => {
    setSearchVal(tipTitle);
    dropdown.hide();

    setSearchHistory(tipTitle)
    //执行搜索
    // navigate('/search/' + encodeURIComponent(tipTitle));
    navigate('/search/' + tipTitle);
  }

  const clearHistorys = () => {
    if (window.confirm('确定要清空所有搜索历史记录吗？')) { clearSearchHistory(); setRefresh(!refresh); }
  }
  const removeHistorys = (_inx: number) => {
    removeSearchHistory(_inx); setRefresh(!refresh);
  }

  const HistoryView = () => {
    const hisarr = getSearchHistory();
    if (hisarr.length < 1) return null;
    return (
      <div className="p-3">
        <div className="flex p-2 justify-between font-bold">
          <span className="text-sm ">搜索历史</span>
          <span className="text-sm text-red-600 cursor-pointer" onClick={clearHistorys}>清空</span>
        </div>
        <ul className="p-2 grid grid-cols-2 gap-3">
          {hisarr.map((item: string, _inx: number) =>
            <li
              className=" cursor-pointer"
              onClick={(e) => {
                searchTipClick(item)
                e.stopPropagation();
              }}>
              <span id="badge-dismiss-dark" className="inline-flex items-center px-2 py-1 me-2 text-sm font-medium text-gray-800 bg-gray-100 rounded dark:bg-gray-700 dark:text-gray-300">
                {item}
                <button onClick={(e) => { removeHistorys(_inx); e.stopPropagation(); }} type="button" className="inline-flex items-center px-1 py-2 ms-2 text-sm text-gray-400 bg-transparent rounded-sm hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-gray-600 dark:hover:text-gray-300" data-dismiss-target="#badge-dismiss-dark" aria-label="Remove">
                  <svg className="w-2 h-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 14">
                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6" />
                  </svg>
                  <span className="sr-only">Remove</span>
                </button>
              </span>
            </li>
          )}
        </ul>
      </div>
    )
  }

  const SortView = () => {
    if (sortLoading) return <LoadingView />
    if ((sortList ?? []).length < 1) return null;
    return (
      <div className="p-3">
        <div className="flex bg-gray-200 p-2 justify-between font-bold rounded">
          <span className="text-sm ">分类</span>
        </div>
        <ul className="p-2 grid grid-cols-2 gap-3">
          {sortList.map(({ id, Name, Img, Type }: any, _inx: number) =>
            <li>
              <div onClick={() => {
                navigate(`/list/${Type}/${id}`);
              }} className="cursor-pointer bg-gray-200  dark:bg-gray-700 flex  items-center w-fit rounded pl-2">
                <img className="w-5 h-5" src={Img} />
                <span id="badge-dismiss-dark" className="inline-flex items-center p-2 me-2 text-base font-medium text-blue-800  dark:text-gray-300">
                  {Name}
                </span>
              </div>
            </li>
          )}
        </ul>
      </div>
    )
  }

  const { data, isLoading, refetch } = useQuery(['search', key, page], () => PostData({
    url: "/Home/Search", jsonData: { key, page }
  }));

  const { data: sortList, isLoading: sortLoading, } = useQuery(['SortList', key, page], () => PostData({
    url: "/Home/SortList", jsonData: { key, page }
  }));

  const submit = (e: any) => {
    searchTipClick(searchVal);
    e.preventDefault(); //阻止提交
  }

  const goPage = (pageIndex: number) => {
    navigate(`/search/${key}/${pageIndex}`);
  }

  return (
    <div className='container mx-auto  max-w-screen-xl flex flex-col pb-40 md:pt-5 md:pb-3'>
      <div className=" fixed w-full md:hidden">
        <section className="bg-gray-50 dark:bg-gray-900 flex ">
          <div className="max-w-screen-xl mx-auto w-full">
            <div className="relative bg-white shadow dark:bg-gray-800 sm:rounded-b-lg">
              <div className="flex flex-col items-center justify-between p-4 space-y-3 md:flex-row md:space-y-0 md:space-x-4">
                <div className="w-full md:w-1/2">
                  <form className="flex items-center" onSubmit={submit}>
                    <div className="relative flex-1">
                      <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        <svg className="w-4 h-4 text-gray-500 dark:text-gray-400" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
                          <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z" />
                        </svg>
                      </div>
                      <input type="search"
                        ref={inputRef}
                        onInput={inputHandler}
                        onFocus={focusHandler}
                        onKeyDown={keyDownHandler}
                        value={searchVal}
                        className="block w-full p-4 ps-10 text-base text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="产品、品牌、别名、疾病名称" />
                      <button type="submit" className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-md text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800">搜索</button>
                      {searchVal.length > 0 &&
                        <button className="absolute end-20 bottom-4"
                          onClick={() => searchTipClick('')}
                        >
                          <svg className="w-6 h-6 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" viewBox="0 0 24 24">
                            <path fill-rule="evenodd" d="M2 12C2 6.477 6.477 2 12 2s10 4.477 10 10-4.477 10-10 10S2 17.523 2 12Zm7.707-3.707a1 1 0 0 0-1.414 1.414L10.586 12l-2.293 2.293a1 1 0 1 0 1.414 1.414L12 13.414l2.293 2.293a1 1 0 0 0 1.414-1.414L13.414 12l2.293-2.293a1 1 0 0 0-1.414-1.414L12 10.586 9.707 8.293Z" clip-rule="evenodd" />
                          </svg>
                        </button>
                      }
                      <div
                        ref={dropdownDomRef}
                        className="z-30 hidden border border-gray-300 bg-white divide-y divide-gray-100 rounded-lg shadow w-full dark:bg-gray-700">
                        <ul className="py-2 text-sm text-gray-700 dark:text-gray-200" aria-labelledby="dropdownButton">
                          {tipDatas.map((tipTitle: string, _ind: number) => {
                            const choose = _ind == selectIndex;
                            return <li onClick={() => searchTipClick(tipTitle)}>
                              <a className={choose ? "block px-4 py-2 bg-gray-200 dark:bg-gray-600 dark:text-white" : "block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"}>{tipTitle}</a>
                            </li>
                          })}
                        </ul>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <div className="h-[90px] w-full md:hidden"></div>

      {isLoading ?
        <LoadingView />
        :
        <>
          {(data == undefined || data == null) ?
            <>
              <HistoryView />
              <SortView />
            </>
            :
            <>
              <div className='hidden md:flex top-[60px] justify-between items-center p-2 fixed md:relative md:top-0 bg-white w-full '>
                <h5 className="text-lg font-bold dark:text-white">{`搜索 '${key}' 结果`}</h5>
                <div className='flex items-center'>
                  <span className='text-sm text-gray-400 dark:text-white'>查询数量({data.total}条)</span>
                </div>
              </div>
              {data.items.length > 0 ?
                <>
                  <div className=''>
                    <ul className='flex flex-col md:flex-row md:flex-wrap'>
                      <ListView items={data.items} proItemClick={({ e, id, imgPath, title }: any) => ProItemClick({ e, id, imgPath, title, navigate })} />
                    </ul>
                  </div>
                  <PaginationView pageIndex={data.pageIndex} totalPage={data.totalPage} goPage={goPage} />
                </>
                :
                <NoDataView />
              }
            </>
          }
        </>
      }
    </div>
  );
}
