import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';

import {
  useQuery,
  useMutation,
  useQueryClient,
  QueryClient,
  QueryClientProvider,
} from 'react-query'
import { NotifyProvider } from './common/useNotify';
import { R } from './router';
import { ShoppingCarCountContext, ShoppingCarCountProvider } from './common/useShoppingCarCount';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

root.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <NotifyProvider>
        <ShoppingCarCountProvider>
          <R />
        </ShoppingCarCountProvider>
      </NotifyProvider>
    </QueryClientProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
