import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { IApiResult, PostData } from "../common/request";
import { useNotify } from "../common/useNotify";
import { decodeToken, getToken, isLogin, setToken, WapFooterTxt } from "../common/global";
import { useNavigate } from "react-router-dom";
import { Modal } from 'flowbite'
import { useEffect } from "react";

export const ResetPwdPage = () => {
  const notify = useNotify();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const { mutate: resetpwd, isLoading } = useMutation({
    mutationFn: (data: any) => {
      return PostData({
        url: "/User/UserReSetPwd", jsonData: data, fail: ({ Code, Message, Result }: IApiResult) => {
          notify.show({ message: Message, type: 'info' });
        }
      });
    },
    onSuccess: ({ token }: any) => {
      notify.show({ message: '密码修改成功', type: 'success' });
      setToken(token);
      navigate('/');
    },
    onError: (message: string) => {
      // console.error(message);
    }
  });

  const onSubmit = (data: any) => {
    // console.log('data', data);
    resetpwd(data);
  }

  useEffect(() => {
    //check login
    if (isLogin()) {
      const { role } = decodeToken();
      console.log(role)
      if (role.indexOf('FWS') >= 0)
        navigate('/fws');
      else
        navigate('/user');
    }
  }, [])

  return (
    <div className=" container mx-auto  max-w-screen-xl flex items-center justify-between flex-col md:flex-row pb-24">
      {/* <img className="hidden xl:block" src='https://www.xirou.com/App_Themes/UI/Images/Paizhao.png' /> */}
      <div className="flex flex-col items-center justify-center px-6 py-6 md:py-24 w-full">
        <a className="flex items-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white">
          <img className="w-8 h-8 mr-2 rounded-full" src="xirou.png" alt="logo" />
          Xirou
        </a>
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-xl font-bold leading-tight tracking-tight text-gray-900 md:text-2xl dark:text-white">
              重新设置密码
            </h1>
            <form className="space-y-4 md:space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <div>
                <label htmlFor="account" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">帐号(手机/邮箱)</label>
                <input type="text" {...register("account")} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
              </div>
              <div>
                <label htmlFor="pwd" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">输入新密码</label>
                <input type="password" {...register("pwd")} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
              </div>
              <div>
                <label htmlFor="pwd2" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">确认新密码</label>
                <input type="password" {...register("pwd2")} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" />
              </div>
              <div>
                <label htmlFor="code" className="block mb-2 text-sm font-medium text-gray-900 dark:text-white">验证码</label>
                <div className="flex justify-between space-x-3">
                  <input type="password" {...register("code")} className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500 flex-1 items-center" />
                  <button type="button" className="text-white bg-primary-600 hover:bg-primary-800 focus:ring-4 focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5  dark:bg-primary-600 dark:hover:bg-primary-700 focus:outline-none dark:focus:ring-primary-800">发送验证码</button>
                </div>
              </div>
              <div className="flex items-start">
                <div className="flex items-center h-5">
                  <input id="terms" aria-describedby="terms" type="checkbox" className="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" checked />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="terms" className="font-light text-gray-500 dark:text-gray-300">我已阅读并同意<a className="font-medium text-primary-600 hover:underline dark:text-primary-500" href="#">《XIROU用户注册使用协议》</a></label>
                </div>
              </div>
              <button disabled={isLoading} type="submit" className="w-full text-white bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800 disabled:opacity-70">{isLoading ? '提交中...' : '确认修改密码'}</button>
              <p className="text-sm font-light text-gray-500 dark:text-gray-400">
                已经有帐号? <a onClick={() => navigate('/login')} className="font-medium text-primary-600 hover:underline dark:text-primary-500">登陆</a>
              </p>
            </form>
          </div>
        </div>
      </div>
      <WapFooterTxt />
    </div>
  )
};
