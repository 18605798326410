import { initFlowbite } from 'flowbite';
import { useEffect, useState } from 'react';
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useNotify } from './common/useNotify';
import { BaseUrl, DengView, ListView, LoadingView, sleep, WapFooterTxt } from './common/global';
import { ProItemClick } from './common/detailTransition';
import Marquee from 'react-easy-marquee';
import { useQuery } from 'react-query';
import { PostData } from './common/request';
import { PCMenus } from './components/headerAndFooterView';

function App() {
  const [setHeaderTitle] = useOutletContext() as any;
  const navigate = useNavigate();
  const notify = useNotify();

  const carousels = [
    { url: 'https://www.xirou.com/UploadFolder/2017-12-18/2017121815304358108487-800-360.png' },
    { url: 'https://www.xirou.com/UploadFolder/2017-12-18/2017121815320393908493-800-360.png' },
    { url: 'https://www.xirou.com/UploadFolder/2017-12-18/2017121815324327001468-800-360.png' },
    { url: 'https://www.xirou.com/UploadFolder/2017-12-18/2017121815332808803127-800-360.png' },
  ]

  const { data, isLoading, isSuccess, isError, error, refetch } = useQuery(['WapData_SortDTOs'], () => PostData({
    url: "/Home/WapData", jsonData: {}
  }));

  useEffect(() => {
    setHeaderTitle('细柔-加拿大药品跨境自购平台')
  }, []);
  useEffect(() => {
    initFlowbite();
  }, [isLoading]);

  if (isLoading)
    return <LoadingView />
  if (isError) {
    return <div>{`${error}`}</div>
  }
  if (!isSuccess || data == undefined || data == null) {
    return <div>{`未知错误!`}</div>
  }

  return (
    <div className=' container mx-auto  max-w-screen-xl flex flex-col pb-24'>
      <div className='flex'>
        <div className='flex-1 hidden xl:block'>
          <div className="z-10 w-48 bg-[#3388CD]"  >
            <PCMenus data={data} />
          </div>
        </div>
        <div className='w-full xl:w-[892px] flex flex-col items-stretch'>
          <div className="relative " data-carousel="slide">
            <div className="relative h-56 overflow-hidden md:h-96">
              {carousels.map(({ url }) =>
                <div className="hidden duration-700 ease-in-out" data-carousel-item>
                  <img src={url} className="object-cover absolute block h-full xl:w-full -translate-x-1/2 -translate-y-1/2 top-1/2 left-1/2" alt="..." />
                </div>
              )}
            </div>
            <button type="button" className="absolute top-0 start-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-prev>
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 1 1 5l4 4" />
                </svg>
                <span className="sr-only">Previous</span>
              </span>
            </button>
            <button type="button" className="absolute top-0 end-0 z-30 flex items-center justify-center h-full px-4 cursor-pointer group focus:outline-none" data-carousel-next>
              <span className="inline-flex items-center justify-center w-10 h-10 rounded-full bg-white/30 dark:bg-gray-800/30 group-hover:bg-white/50 dark:group-hover:bg-gray-800/60 group-focus:ring-4 group-focus:ring-white dark:group-focus:ring-gray-800/70 group-focus:outline-none">
                <svg className="w-4 h-4 text-white dark:text-gray-800 rtl:rotate-180" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 6 10">
                  <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m1 9 4-4-4-4" />
                </svg>
                <span className="sr-only">Next</span>
              </span>
            </button>
          </div>
          <div className='mx-auto md:mt-0.5 w-full'>
            <a onClick={() => navigate(`/detail/5502`)}><img className='w-full md:h-[122px]' src='https://www.xirou.com/App_Themes/UI/Images/syp.jpg' /></a>
          </div>
          <div className='hidden mx-auto mt-0.5 md:block w-full '>
            <img className='w-full md:h-[120px]' src='https://www.xirou.com/App_Themes/UI/Images/fj_799_121.jpg' />
          </div>
        </div>
        <div className='flex-1 hidden xl:block  bg-[#F2F2F2]'>
          <div className='divide-y flex flex-col h-full'>
            <a className='flex items-center flex-1 justify-between' href='https://www.xirou.org/' target='_blank'>
              <div className='leading-7 pl-4'>
                <div className='text-[#ff6600]'>细柔医生</div>
                <div className='text-[#003969]'>转诊/看病</div>
                <div className='text-[#333] text-sm'>全球医疗</div>
              </div>
              <div className='pr-4'>
                <img className='w-16' src='https://www.xirou.com/App_Themes/UI/Images/images/%E6%9C%AA%E6%A0%87%E9%A2%98-1_03.png' />
              </div>
            </a>
            <a className='flex items-center flex-1 justify-between' href='/help/42'>
              <div className='leading-7 pl-4'>
                <div className='text-[#ff6600]'>免费药品</div>
                <div className='text-[#003969]'>实验/观察</div>
                <div className='text-[#333] text-sm'>临床入组</div>
              </div>
              <div className='pr-4'>
                <img className='w-16' src='https://www.xirou.com/App_Themes/UI/Images/images/%E6%9C%AA%E6%A0%87%E9%A2%98-1_03-07.png' />
              </div>
            </a>
            <a className='flex items-center flex-1 justify-between' href='/help/43'>
              <div className='leading-7 pl-4'>
                <div className='text-[#ff6600]'>参比药品</div>
                <div className='text-[#003969]'>研发/临床</div>
                <div className='text-[#333] text-sm'>药企专用</div>
              </div>
              <div className='pr-4'>
                <img className='w-16' src='https://www.xirou.com/App_Themes/UI/Images/images/%E6%9C%AA%E6%A0%87%E9%A2%98-1_03-03.png' />
              </div>
            </a>
            <a className='flex items-center flex-1 justify-between' href='/help/39' >
              <div className='leading-7 pl-4'>
                <div className='text-[#ff6600]'>会诊购药</div>
                <div className='text-[#003969]'>美国/加拿大</div>
                <div className='text-[#333] text-sm'>诊疗流程</div>
              </div>
              <div className='pr-4'>
                <img className='w-16' src='https://www.xirou.com/App_Themes/UI/Images/images/cms.png' />
              </div>
            </a>
            <a className='flex items-center flex-1 justify-between' href='/help/14'>
              <div className='leading-7 pl-4'>
                <div className='text-[#ff6600]'>供药证件</div>
                <div className='text-[#003969]'>官方/第三方</div>
                <div className='text-[#333] text-sm'>质量文件</div>
              </div>
              <div className='pr-4'>
                <img className='w-16' src='https://www.xirou.com/App_Themes/UI/Images/images/%E6%9C%AA%E6%A0%87%E9%A2%98-1_03-05.png' />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div className='py-2 bg-[#006090] text-center md:hidden'>
        <DengView />
      </div>

      <div className=''>
        <ul>
          {
            data.filter((t: any) => t.Type != 'supply').map(({ id, Name, Type, Img, AdPic, _FirstItem: { items } }: any, _ind: number) => {
              const wapStyle = Type == 'pytj' ? 'md:hidden' : '';
              const PCStyle = Type == 'dtgx' ? 'hidden md:block' : '';
              return <li className={`${wapStyle} ${PCStyle} mt-2 bg-white`}>
                <div className='flex items-center p-2 justify-between'>
                  {/* <img className='w-5 h-5 mr-1' src={Img} /> */}
                  <h5 className="cursor-pointer text-lg dark:text-white" onClick={() => navigate(`/list/${Type}/${id}`)}>{Name}</h5>
                  {Type == 'dtgx' ?
                    null
                    :
                    <div className='cursor-pointer flex items-center' onClick={() => navigate(`/list/${Type}/${id}`)}>
                      <span className='text-md text-gray-500 dark:text-white'>全部</span>
                      <svg className="w-4 h-4 text-gray-500 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                        <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m9 5 7 7-7 7" />
                      </svg>
                    </div>
                  }
                </div>
                {/* Wap */}
                {items.length > 0 ?
                  <ul className='md:hidden'>
                    <ListView showRIcon={true} items={[items[0]]} proItemClick={({ e, id, imgPath, title }: any) => ProItemClick({ e, id, imgPath, title, navigate })} />
                  </ul>
                  : <div className='md:hidden'>无数据</div>
                }
                <div className='hidden border md:block'>
                  {/* PC 显示二级分类 */}
                  {(Type == 'sort' || Type == 'pytj' || Type == 'dtgx') && <PCIndexErJiView key={`${Type}-${id}`} Type={Type} AdPic={AdPic} pid={id} />}
                </div>
              </li>
            }
            )}
        </ul>
      </div>

      {/* <p className="mt-1 mx-auto text-gray-500">App Page</p>
      <p className="mt-1 mx-auto text-gray-500 text-2xl">Home</p>
      <div className="inline-flex rounded-md shadow-sm mt-12" role="group">
        <button onClick={() => navigate('/login')} type="button" className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-s-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
          登陆
        </button>
        <button onClick={() => navigate('/user')} type="button" className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
          会员中心
        </button>
        <button onClick={() => navigate('/fws')} type="button" className="px-4 py-2 text-sm font-medium text-gray-900 bg-white border border-gray-200 rounded-e-lg hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 focus:text-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white dark:hover:text-white dark:hover:bg-gray-700 dark:focus:ring-blue-500 dark:focus:text-white">
          服务商中心
        </button>
      </div> */}

      <WapFooterTxt />
    </div>
  );
}

const PCIndexErJiView = ({ Type, AdPic, pid }: any) => {
  const navigate = useNavigate();
  const [index, setIndex] = useState(0);

  const { data, isLoading, isSuccess, isError, error, refetch } = useQuery(['PCIndexErJiView', Type, pid], () => PostData({
    url: "/Home/PCIndexErJi", jsonData: { type: Type, pid }
  }));
  if (isLoading)
    return <LoadingView />
  if (isError) {
    return <div>{`${error}`}</div>
  }
  if (!isSuccess || data == undefined || data == null) {
    return <div>{`未知错误!`}</div>
  }

  return (
    <>
      <ul className='flex flex-wrap bg-[#F2F2F2]'>
        {data.map(({ id, Type, Name }: any, _ind: number) => <li onClick={() => navigate(`/list/${Type}/${pid}/${id}`)} onMouseOver={() => setIndex(_ind)} className={`cursor-pointer text-sm border border-l-0  border-t-0 border-b-1 border-r-1 w-1/6 text-center py-2 ${_ind == index ? 'text-[#ff6600] bg-white' : 'text-[#003969]'}`}>{Name}</li>)}
      </ul>
      <div className='flex items-stretch'>
        <div>
          <img className='w-[192px]' src={AdPic} />
        </div>
        <PCItemsView key={`${Type}-${pid}-${index}`} items={data[index]._FirstItem.items} />
      </div>
    </>
  );
}

const PCItemsView = ({ items }: any) => {
  const navigate = useNavigate();
  return (<>
    <div className='flex-1 divide-y self-stretch flex flex-col'>
      <ul className='flex divide-x h-2/3'>
        <ListView PCItem={true} showRIcon={true} items={items.slice(0, Math.min(3, items.length))} proItemClick={({ e, id, imgPath, title }: any) => ProItemClick({ e, id, imgPath, title, navigate })} />
      </ul>
      <ul className='flex divide-x h-1/3'>
        <ListView PCItem2={true} showRIcon={true} items={items.length > 3 ? items.slice(3, Math.min(6, items.length)) : []} proItemClick={({ e, id, imgPath, title }: any) => ProItemClick({ e, id, imgPath, title, navigate })} />
      </ul>
    </div>
    <ul className='w-[220px] border border-l-1 self-stretch border-y-0 border-r-0 divide-y' >
      <ListView PCItem3={true} showRIcon={true} items={items.length > 6 ? items.slice(6, Math.min(9, items.length)) : []} proItemClick={({ e, id, imgPath, title }: any) => ProItemClick({ e, id, imgPath, title, navigate })} />
    </ul>
  </>)
}

export default App;
