import { initFlowbite } from 'flowbite';
import { useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate, useOutletContext, useParams, useSearchParams } from 'react-router-dom';
import { PostData } from '../common/request';
import { ListView, LoadingView, NoDataView } from '../common/global';
import { ProItemClick } from '../common/detailTransition';
import { PaginationView } from '../common/pagination';

export const List = () => {
  const [setHeaderTitle] = useOutletContext() as any;
  const navigate = useNavigate();

  const { type = '', sid = 0, sid2 = 0, page = 0 } = useParams();

  const [sParams] = useSearchParams();
  const status: number = JSON.parse(sParams.get('status') ?? '0');
  // console.log('sParams', status)

  useEffect(() => {
    initFlowbite();
    setHeaderTitle('Loading');
  }, []);

  const { data, isLoading, isSuccess, isError, error, refetch } = useQuery(['search', type, sid, sid2, status, page], () => PostData({
    url: "/Home/List", jsonData: { type, sid, sid2, page, status }
  }));

  if (isLoading)
    return <LoadingView />
  if (isError) {
    return <div>{`${error}`}</div>
  }
  if (!isSuccess || data == undefined || data == null) {
    return <div>{`未知错误!`}</div>
  }

  const { title, items, pageIndex, pageSize, totalPage, total } = data as any;
  setHeaderTitle(title);

  const radioChange = (val: any) => {
    // console.log(val);
    goPage(0, val);
  }
  const goPage = (pageIndex: number, _status: number) => {
    navigate(`/list/${type}/${sid}/${sid2}/${pageIndex}?status=${_status ?? status}`);
  }

  return (
    <div className='pt-[44px]container mx-auto  max-w-screen-xl flex flex-col pb-40 md:pt-5 md:pb-3'>
      <div className=''>
        <div className='flex top-[60px] space-x-2 items-center p-3 fixed md:relative md:top-0 bg-white w-full '>
          <h5 className="text-lg font-bold dark:text-white">{title}</h5>
          <div className=''>
            <ul className="items-center w-full text-sm font-medium text-gray-900 bg-white rounded-lg flex">
              <li className="w-full  border-gray-200 ">
                <div className="flex items-center ps-3">
                  <input onChange={(e) => radioChange(e.currentTarget.value)} checked={status == 0} id="vue-checkbox-list-1" name="vue-checkbox-list" type="radio" value="0" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="vue-checkbox-list-1" className="w-full py-0 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">全部</label>
                </div>
              </li>
              <li className="w-full  border-gray-200 ">
                <div className="flex items-center ps-3">
                  <input onChange={(e) => radioChange(e.currentTarget.value)} checked={status == 1} id="vue-checkbox-list1" name="vue-checkbox-list" type="radio" value="1" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="vue-checkbox-list1" className="w-full py-0 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">有价</label>
                </div>
              </li>
              <li className="w-full  border-gray-200 ">
                <div className="flex items-center ps-3">
                  <input onChange={(e) => radioChange(e.currentTarget.value)} checked={status == 2} id="vue-checkbox-list2" name="vue-checkbox-list" type="radio" value="2" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="vue-checkbox-list2" className="w-full py-0 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">预定</label>
                </div>
              </li>
              <li className="w-full border-gray-200 ">
                <div className="flex items-center ps-3">
                  <input onChange={(e) => radioChange(e.currentTarget.value)} checked={status == 3} id="vue-checkbox-list3" name="react-checkbox-list" type="radio" value="3" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="vue-checkbox-list3" className="w-full py-0 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">恢复</label>
                </div>
              </li>
              <li className="w-full  border-gray-200 ">
                <div className="flex items-center ps-3">
                  <input onChange={(e) => radioChange(e.currentTarget.value)} checked={status == 4} id="vue-checkbox-list4" name="angular-checkbox-list" type="radio" value="4" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-700 dark:focus:ring-offset-gray-700 focus:ring-2 dark:bg-gray-600 dark:border-gray-500" />
                  <label htmlFor="vue-checkbox-list4" className="w-full py-0 ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">会诊</label>
                </div>
              </li>
            </ul>
          </div>
          <div className='flex-1'></div>
          <div className='flex items-center'>
            <span className='text-sm text-gray-400 dark:text-white'>查询数量({total}条)</span>
          </div>
        </div>
        {items.length > 0 ?
          <ul className='flex flex-col mt-[52px] md:mt-0 md:flex-row md:flex-wrap'>
            <ListView items={items} proItemClick={({ e, id, imgPath, title }: any) => ProItemClick({ e, id, imgPath, title, navigate })} />
          </ul>
          :
          <NoDataView />
        }
      </div>
      <PaginationView pageIndex={data.pageIndex} totalPage={data.totalPage} goPage={goPage} />
    </div>
  );
} 
