export const PaginationView = ({ pageIndex, totalPage, goPage }: any) => {

  const pageNum = pageIndex + 1;
  const pageNumArr = [];
  for (var i = -2; i < 3; i++) {
    const nPageNum = pageNum + i;
    if (nPageNum < 1 || nPageNum > totalPage) continue;
    pageNumArr.push(nPageNum);
  }

  return (
    <nav className=' self-center fixed bottom-24 md:relative md:bottom-0 md:py-5'>
      <ul className="inline-flex -space-x-px text-base h-10">
        {
          totalPage == 1 ?
            <li>
              <a aria-current="page" className="flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white rounded-full">只有1页</a>
            </li>
            :
            <>
              {pageNum > 1 ?
                <li>
                  <a onClick={() => goPage(pageIndex - 1)} className="cursor-pointer flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-500 bg-white border border-e-0 border-gray-300 rounded-s-full hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">上一页</a>
                </li>
                :
                <li>
                  <a className="flex items-center justify-center px-4 h-10 ms-0 leading-tight text-gray-300 bg-white border border-e-0 border-gray-300 rounded-s-full  dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 ">上一页</a>
                </li>
              }
              {pageNumArr.map((_pageNum) => {
                if (_pageNum == pageNum)
                  return <li>
                    <a aria-current="page" className="flex items-center justify-center px-4 h-10 text-blue-600 border border-gray-300 bg-blue-50 hover:bg-blue-100 hover:text-blue-700 dark:border-gray-700 dark:bg-gray-700 dark:text-white">{_pageNum}</a>
                  </li>
                else
                  return <li onClick={() => goPage(_pageNum - 1)}>
                    <a className="cursor-pointer flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">{_pageNum}</a>
                  </li>
              })}
              {pageNum < totalPage ?
                <li>
                  <a onClick={() => goPage(pageIndex + 1)} className="cursor-pointer flex items-center justify-center px-4 h-10 leading-tight text-gray-500 bg-white border border-gray-300 rounded-e-full hover:bg-gray-100 hover:text-gray-700 dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white">下一页</a>
                </li>
                :
                <li>
                  <a className="flex items-center justify-center px-4 h-10 leading-tight text-gray-300 bg-white border border-gray-300 rounded-e-full dark:bg-gray-800 dark:border-gray-700 dark:text-gray-400">下一页</a>
                </li>
              }
            </>
        }
      </ul>
    </nav>
  )
}