import { sleep } from "./global";

const pro_transition = (event: any, imgPath: string) => {
  const x = event.clientX - event.nativeEvent.offsetX;
  const y = event.clientY - event.nativeEvent.offsetY;
  const img = document.createElement('div');
  img.style.backgroundColor = '#F9FAFB';
  img.style.position = 'fixed';
  img.style.zIndex = '48';
  img.style.left = x + 9 + 'px';
  img.style.top = y + 9 + 'px';
  img.style.width = '96px';
  img.style.height = '96px';
  img.style.opacity = '0.3';
  img.style.border = '1px solid red';
  img.style.borderRadius = '8px';
  // img.style.overflow = 'hidden';
  img.innerHTML = `<img class='object-cover' style='height:100%' src='${imgPath}' />`;
  img.style.transition = 'all 0.5s ease';
  document.body.appendChild(img);
  // 使用 requestAnimationFrame 替代 setTimeout
  requestAnimationFrame(() => {
    img.style.left = '0px';
    img.style.top = '60px';
    img.style.right = '0px';
    img.style.width = '100%';
    img.style.height = '224px';
    img.style.border = 'none';
    img.style.borderRadius = '0px';
    img.style.opacity = '1';
    img.style.display = 'flex';
    img.style.justifyContent = 'center';
  });
  img.ontransitionend = function () {
    img.remove();
  };
}

export const ProItemClick = async ({ e, id, title, imgPath, navigate }: any) => {
  // pro_transition(e, imgPath);
  // await sleep(500);
  navigate(`/detail/${id}`, { state: { title, imgPath } });
}