import qs from "qs";
import { useEffect, useLayoutEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { decodeToken, get_Spm, getToken, isDevelopment, set_Spm, sleep } from "./global";

const AutoScorllTop = ({ children }: any) => {
	const navigate = useNavigate();
	const location = useLocation();
	useLayoutEffect(() => {
		document.documentElement.scrollTo(0, 0);
	}, [location.pathname]);

	useEffect(() => {
		(async () => {
			const { state, pathname, search = "" } = location;
			const searchJson = qs.parse(search.indexOf('?') == 0 ? search.substring(1) : search);
			const { spm = "" } = searchJson; //url spm
			const { Sign = "" }: any = decodeToken(); //token sign
			const _spm = get_Spm() ?? ""; //LocalStorage spm

			const publicPathnames = ['/login', '/password', '/resetpwd', '/error'];
			const _pathname = pathname.toLocaleLowerCase();
			// console.log('_pathname', location)
			if (publicPathnames.indexOf(_pathname) >= 0) {
				return;
			}
			else {
				var N_spm = Sign || spm || _spm; //priority

				//1
				if (N_spm == "") {
					navigate('/password', { replace: true }); return;
				}

				//2
				if (N_spm != _spm) {
					//save spm to LocalStorage
					set_Spm(N_spm); await sleep(200);
				}

				//3
				if (N_spm != spm) {
					if (isDevelopment) console.log('change Page spm parameter', pathname, N_spm);
					//Finally, N_spm shall prevail
					navigate(pathname + `?${qs.stringify({ ...searchJson, spm: N_spm })}`, { state, replace: true });
				}
			}
		})();
	}, [location.pathname, location.search])

	return children;
};

export default AutoScorllTop; 