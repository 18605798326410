import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { IApiResult, PostData, Get } from "../../common/request";
import { useNotify } from "../../common/useNotify";
import { BaseUrl, decodeToken, getToken, isLogin, LoadingView, setToken, WapFooterTxt } from "../../common/global";
import { useNavigate, useOutletContext, useParams } from "react-router-dom";
import { initFlowbite, Modal } from 'flowbite'
import { useEffect, useState } from "react";
import { useShoppingCarCount } from "../../common/useShoppingCarCount";

export const ShoppingCar = () => {
    const [setHeaderTitle] = useOutletContext() as any;
    const navigate = useNavigate();
    const { shoppingCarCount, refetchShoppingCarCount } = useShoppingCarCount();
    const notify = useNotify();

    const [unchecked, setUnchecked] = useState<number[]>([]);

    const { data, isLoading, isFetching, isSuccess, isError, error, refetch } = useQuery(['GetShoppingCartList'], () => Get({
        url: "/Member/ShoppingCar/GetShoppingCartList", jsonData: {}
    }), { refetchOnWindowFocus: false });

    const { mutate: editShoppingCarCount, isLoading: editShoppingCarCount_isLoading } = useMutation({
        mutationFn: (data: any) => {
            return PostData({
                url: "/Member/ShoppingCar/EditShoppingCarCount", jsonData: data,
                fail: ({ Code, Message, Result }: IApiResult) => {
                    notify.show({ message: Message, type: 'error' });
                }
            });
        },
        onSuccess: async ({ }: any) => {
            // notify.show({ message: 'Success', type: 'success' });
            refetch();
            refetchShoppingCarCount();
        },
        onError: (message: string) => {
            // console.error(message);
        }
    });
    const { mutate: clearAllShoppingCar } = useMutation({
        mutationFn: (data: any) => {
            return PostData({
                url: "/Member/ShoppingCar/ClearAllShoppingCar", jsonData: data,
                fail: ({ Code, Message, Result }: IApiResult) => {
                    notify.show({ message: Message, type: 'error' });
                }
            });
        },
        onSuccess: async ({ }: any) => {
            // notify.show({ message: 'Success', type: 'success' });
            refetch();
            refetchShoppingCarCount();
        },
        onError: (message: string) => {
            // console.error(message);
        }
    });

    useEffect(() => {
        setHeaderTitle('购物车');
        initFlowbite();
        refetchShoppingCarCount();
    }, [isLoading]);

    if (isLoading)
        return <LoadingView />
    if (isError) {
        return <div>{`${error}`}</div>
    }
    if (!isSuccess || data == undefined || data == null) {
        return <div>{`未知错误!`}</div>
    }

    const cut = (count: number, id: number) => {
        if (!isLoading && !editShoppingCarCount_isLoading)
            editShoppingCarCount({ count: count - 1, pid: id });
    }
    const add = (count: number, id: number) => {
        if (!isLoading && !editShoppingCarCount_isLoading)
            editShoppingCarCount({ count: count + 1, pid: id });
    }

    const checkBoxClick = (checked: boolean, id: number) => {
        console.log('checked', checked, id)
        const _unchecked = [...unchecked];
        const _i = _unchecked.indexOf(id);
        if (checked) {
            if (_i >= 0) _unchecked.splice(_i, 1);
        }
        else {
            if (_i < 0) _unchecked.push(id);
        }
        setUnchecked(_unchecked);
    }
    const getCheckBoxChecked = (id: number) => {
        return unchecked.indexOf(id) < 0;
    }
    const allCheckBoxClick = (checked: boolean, ids: number[]) => {
        const _unchecked = [...unchecked];
        if (checked) {
            for (var i in ids) {
                const _i = _unchecked.indexOf(ids[i])
                if (_i >= 0) _unchecked.splice(_i, 1);
            }
        }
        else {
            for (var i in ids) {
                const _i = _unchecked.indexOf(ids[i])
                if (_i < 0) _unchecked.push(ids[i]);
            }
        }
        setUnchecked(_unchecked);
    }
    const getAllCheckBoxChecked = (ids: number[]) => {
        console.log('ids', ids);
        for (var i in unchecked) {
            if (ids.indexOf(unchecked[i]) >= 0) return false;
        }
        return true;
    }
    const canDoOrderBtn = (ids: number[]): boolean => {
        for (var i in ids) {
            if (unchecked.indexOf(ids[i]) < 0) return true;
        }
        return false;
    }

    return (
        <div className=" container mx-auto  max-w-screen-xl pb-24 px-4 md:px-0 flex flex-col">
            {data.length > 0 ?
                <>
                    <div className="flex items-center pt-8 pb-4">
                        <h2 className="text-2xl font-extrabold dark:text-white mr-2">购物车</h2>
                        {(isLoading || isFetching || editShoppingCarCount_isLoading) &&
                            <LoadingView />
                        }
                        <div className="flex-1"></div>
                        <button className="relative inline-flex items-center justify-center p-[1.5px] overflow-hidden text-sm font-medium text-gray-900 rounded group bg-gradient-to-br from-pink-500 to-orange-400 group-hover:from-pink-500 group-hover:to-orange-400 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-pink-200 dark:focus:ring-pink-800" onClick={() => {
                            if (window.confirm('您确定要清空购物车吗？')) { clearAllShoppingCar({}) }
                        }}>
                            <span className="relative px-3 py-1.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded group-hover:bg-opacity-0">
                                清空购物车
                            </span>
                        </button>
                    </div>
                    <div className="flex flex-col space-y-3">
                        {data.map(({ key, pros }: any, _index: number) =>
                            // <SPView1 key={key} pros={pros} _index={_index} />
                            <div className="flex flex-col w-full bg-white p-2 md:p-6 border border-gray-200 shadow dark:bg-gray-800 dark:border-gray-700">
                                <div className="flex  justify-between  items-center space-x-3 mb-3">
                                    <p className="font-normal text-gray-700 dark:text-gray-400">包裹{_index + 1}</p>
                                    <button type="button" className="text-white bg-gradient-to-r from-red-400 via-red-500 to-red-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-red-300 dark:focus:ring-red-800   dark:shadow-lg dark:shadow-red-800/80 font-medium rounded text-sm px-2 py-0.5 text-center" onClick={() => {
                                        if (window.confirm('您确定要清空该包裹吗？')) { clearAllShoppingCar({ bgId: key }) }
                                    }}>清空包裹</button>
                                </div>
                                <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
                                    <thead className="text-sm text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                                        <tr>
                                            <th scope="col" className="p-2 md:py-2 md:w-auto">
                                                <div className="flex items-center">
                                                    <input id="default-checkbox" type="checkbox" value="" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                        onChange={(e: any) => allCheckBoxClick(e.target.checked, pros.map(({ id }: any) => id))}
                                                        checked={getAllCheckBoxChecked(pros.map(({ id }: any) => id))} />
                                                    <label htmlFor="default-checkbox" className=" hidden md:inline-block ms-2 font-medium text-gray-900 dark:text-gray-300">全选</label>
                                                </div>
                                            </th>
                                            <th scope="col" className="py-2 px-1 md:py-3">
                                                产品
                                            </th>
                                            <th scope="col" className="py-2 px-1 md:py-3  md:w-44 text-center hidden md:table-cell">
                                                单价
                                            </th>
                                            <th scope="col" className="py-2 px-1 md:py-3  md:w-44  text-center">
                                                数量
                                            </th>
                                            <th scope="col" className="py-2 px-1 md:py-3  md:w-44  text-center  hidden md:table-cell">
                                                小计
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {pros.map(({ id, title, price, imgSrc, count, baozhuangTxt, gongsiTxt }: any) =>
                                            <>
                                                <tr className="bg-white md:border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <th scope="row" className="p-1 md:py-2 font-medium text-gray-900 whitespace-nowrap dark:text-white text-center md:text-left md:pl-3">
                                                        <input type="checkbox" className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                                                            onChange={(e: any) => checkBoxClick(e.target.checked, id)}
                                                            checked={getCheckBoxChecked(id)}
                                                        />
                                                    </th>
                                                    <td className="p-1 md:py-2">
                                                        <div className="flex space-x-2 items-center text-sm">
                                                            <img key={BaseUrl + imgSrc} className="w-16 h-16" src={BaseUrl + imgSrc} />
                                                            <div>
                                                                <div className="font-bold break-words break-all">{title}</div>
                                                                <div className="text-xs break-words break-all"><span className=" hidden md:inline-block">含量|包装：</span>{baozhuangTxt}</div>
                                                                <div className="text-xs break-words break-all"><span className=" hidden md:inline-block">产地|公司：</span>{gongsiTxt}</div>
                                                            </div>
                                                        </div>
                                                    </td>
                                                    <td className="p-1 md:py-2  text-center  hidden md:table-cell">
                                                        ¥{price.toLocaleString('en-US')}
                                                    </td>
                                                    <td className="p-1 md:py-2">
                                                        <div className="flex items-center justify-center">
                                                            <button type="button" className={`group text-blue-700 border border-gray-300 hover:bg-gradient-to-r from-cyan-500 to-blue-500 hover:text-white focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500 ${isLoading || editShoppingCarCount_isLoading ? 'opacity-10' : ''}`}
                                                                onClick={() => cut(count, id)}>
                                                                <svg className="w-4 h-4 group-hover:text-white text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14" />
                                                                </svg>
                                                            </button>
                                                            <span className="w-10 text-center">{count}</span>
                                                            <button type="button" className={`group text-blue-700 border border-gray-300  hover:bg-gradient-to-r from-cyan-500 to-blue-500 hover:text-white  focus:outline-none focus:ring-blue-300 font-medium rounded-full text-sm p-2 text-center inline-flex items-center dark:border-blue-500 dark:text-blue-500 dark:hover:text-white dark:focus:ring-blue-800 dark:hover:bg-blue-500 ${isLoading || editShoppingCarCount_isLoading ? 'opacity-10' : ''}`}
                                                                onClick={() => add(count, id)}>
                                                                <svg className="w-4 h-4 group-hover:text-white text-gray-800 dark:text-white" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24">
                                                                    <path stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 12h14m-7 7V5" />
                                                                </svg>
                                                            </button>
                                                        </div>
                                                    </td>
                                                    <td className="p-1 md:py-2  text-center font-bold  hidden md:table-cell text-orange-500">
                                                        ¥{(price * count).toLocaleString('en-US')}
                                                    </td>
                                                </tr>
                                                <tr className="md:hidden bg-white border-b dark:bg-gray-800 dark:border-gray-700">
                                                    <td></td>
                                                    <td colSpan={2}>
                                                        <div className="flex items-center justify-end space-x-7 pb-2 text-orange-500 text-xs pr-2">
                                                            <div className="md:hidden">单价 ¥{price.toLocaleString('en-US')}</div>
                                                            <div>小计 ¥{(price * count).toLocaleString('en-US')}</div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </>
                                        )}
                                    </tbody>
                                </table>
                                <div className="flex justify-between items-center mt-2 md:mt-5">
                                    <div className="text-orange-500 text-sm font-bold">合计 ¥{(
                                        pros.reduce((total: any, { price, count }: any, currentIndex: any, arr: any) => total + price * count, 0)
                                    ).toLocaleString('en-US')}</div>
                                    <button
                                        onClick={() => alert(1)}
                                        type="button" disabled={!canDoOrderBtn(pros.map(({ id }: any) => id))}
                                        className={`self-end text-white  font-medium rounded-lg text-sm px-5 py-2 text-center bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 shadow-lg shadow-blue-500/50 dark:shadow-lg dark:shadow-blue-800/80 disabled:bg-none disabled:bg-gray-300 disabled:shadow-none disabled:opacity-60`}>确定下单</button>
                                </div>
                            </div>
                        )}
                    </div>
                </>
                :
                <div className='flex space-x-3 m-auto mt-10'>
                    <img src="/cart_empty.png" />
                    <div className="flex flex-col  space-y-3 justify-center">
                        <span>您的购物车内没有任何物品</span>
                        <a onClick={() => navigate('/')} className="text-blue-500 cursor-pointer">点击这里继续购物</a>
                        {isFetching &&
                            <div className="text-left rtl:text-right">
                                <div role="status">
                                    <svg className="inline w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                        <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                                    </svg>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            }
        </div >
    )
};