import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useNavigate } from "react-router-dom";
import { useNotify } from "../../common/useNotify";
import { useEffect, useState } from "react";
import { initFlowbite } from "flowbite";

export const User = () => {
  const notify = useNotify();
  const navigate = useNavigate();

  // const [openSidebar, setOpenSidebar] = useState(false)
  useEffect(() => { initFlowbite(); }, []);


  return (
    <div>user msg</div>
  )
};
