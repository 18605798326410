
import React, { useState, createContext, useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { Get } from "./request";
import { isLogin } from "./global";

interface IShoppingCarCountParams {
    shoppingCarCount: number,
    refetchShoppingCarCount: () => void;
}

const ShoppingCarCountContext = createContext<IShoppingCarCountParams>({ shoppingCarCount: 0, refetchShoppingCarCount: () => { } });

const ShoppingCarCountProvider = (props: any) => {

    const { data: shoppingCarCount, isLoading, isSuccess, isError, error, refetch } = useQuery(['GetShoppingCartCount'], () => Get({
        url: "/Member/ShoppingCar/GetShoppingCartCount", jsonData: {}
    }), { enabled: false });

    const _refetch = () => { if (isLogin()) refetch(); }

    return (
        <ShoppingCarCountContext.Provider value={{ shoppingCarCount: shoppingCarCount || 0, refetchShoppingCarCount: _refetch }} {...props}>
            {props.children}
        </ShoppingCarCountContext.Provider >
    );
};

const useShoppingCarCount = () => {
    return useContext(ShoppingCarCountContext);
};
export { ShoppingCarCountContext, ShoppingCarCountProvider, useShoppingCarCount };