import { LocalKey, LocalStorage } from "ts-localstorage";

export const tokenKey = new LocalKey("searchHistory", "");

export const getSearchHistory = () => {
  const historyStr = LocalStorage.getItem(tokenKey) ?? '[]';
  return JSON.parse(historyStr);
}
export const setSearchHistory = (value: string) => {
  value = (value ?? '').trim();
  if (value.length == 0) return;
  var oldHiss = [...getSearchHistory()];
  const oldIndex = oldHiss.indexOf(value);
  if (oldIndex >= 0) oldHiss = removeSearchHistory(oldIndex);
  oldHiss.unshift(value);
  if (oldHiss.length > 10) oldHiss.splice(10)
  LocalStorage.setItem(tokenKey, JSON.stringify(oldHiss))
}
export const removeSearchHistory = (index: number) => {
  var oldHiss = [...getSearchHistory()];
  oldHiss.splice(index, 1);
  LocalStorage.setItem(tokenKey, JSON.stringify([...oldHiss]))
  return [...oldHiss];
}
export const clearSearchHistory = () => LocalStorage.removeItem(tokenKey)