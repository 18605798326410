import { useNavigate, useParams, useSearchParams } from "react-router-dom";

function ErrorPage() {

  //const navigate = useNavigate();
  const [search, setSearch] = useSearchParams();
  const msg = search.get('msg') ?? "";

  const backPage = () => {
    window.history.back();
  }
  const homePage = () => {
    window.location.href = '/';
  }

  return (
    <div className='w-full h-screen flex flex-col justify-center items-center'>
      <p className="mt-1 mx-auto text-gray-500">发生错误</p>
      <p className="mt-1 mx-auto text-gray-500 text-2xl">{msg}</p>
      <div className="flex space-x-3">
        <a onClick={() => backPage()} className=" mt-5 font-medium text-primary-600 dark:text-primary-500 hover:underline">返回上一页</a>
        <a onClick={() => homePage()} className=" mt-5 font-medium text-primary-600 dark:text-primary-500 hover:underline">首页</a>
      </div>

      {/* <button type="button" disabled className="bg-red-500 text-white disabled:bg-gray-300">按钮</button> */}
    </div>
  );

}

export default ErrorPage;
