import { useForm } from "react-hook-form";
import { useMutation, useQuery } from "react-query";
import { IApiResult, PostData } from "../../common/request";
import { useNotify } from "../../common/useNotify";
import { BaseUrl, decodeToken, getToken, isLogin, LoadingView, setToken, WapFooterTxt } from "../../common/global";
import { useNavigate, useParams } from "react-router-dom";
import { initFlowbite, Modal } from 'flowbite'
import { useEffect } from "react";

export const HelpDetail = () => {
    const { id } = useParams();

    return (
        <div className=" container mx-auto  max-w-screen-xl flex items-center justify-between flex-col md:flex-row pb-24">
            <div className="flex my-5 flex-1">
                <HelpListView id={id} />
                <div className="p-4 mx-4 border bg-white divide-y flex-1">
                    <HelpDetailView id={id} />
                </div>
            </div>
            <WapFooterTxt />
        </div>
    )
};
const HelpDetailView = ({ id }: any) => {

    const { data, isLoading, isSuccess, isError, error, refetch } = useQuery(['HelpDetail', id], () => PostData({
        url: "/Home/HelpDetail", jsonData: { id }
    }));

    useEffect(() => {
        initFlowbite();
    }, [isLoading]);

    if (isLoading)
        return <LoadingView />
    if (isError) {
        return <div>{`${error}`}</div>
    }
    if (!isSuccess || data == undefined || data == null) {
        return <div>{`未知错误!`}</div>
    }

    const { Ftitle, PCConet, WapConet } = data;

    return (
        <>
            <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{Ftitle}</h5>
            <p className="font-normal text-sm  text-gray-600 dark:text-gray-400 pt-4">
                <div className="[&_img]:w-3/5 [&_img]:m-auto" dangerouslySetInnerHTML={{ __html: PCConet.replaceAll("/kindup/", BaseUrl + "/kindup/") || '暂无内容' }}></div>
            </p>
        </>
    )
}
const HelpListView = ({ id }: any) => {
    const navigate = useNavigate();
    const { data, isLoading, isSuccess, isError, error, refetch } = useQuery(['PCFooterData'], () => PostData({
        url: "/Home/PCFooterData", jsonData: {}
    }));

    if (isLoading)
        return <LoadingView />
    if (isError) {
        return <div>{`${error}`}</div>
    }
    if (!isSuccess || data == undefined || data == null) {
        return <div>{`未知错误!`}</div>
    }

    return (
        <div className="hidden md:block">
            {data.map(({ Typename, List }: any) =>
                <ul className="w-48 text-sm font-medium text-gray-900 bg-white border border-gray-200  dark:bg-gray-700 dark:border-gray-600 dark:text-white mb-2">
                    <li className="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600">{Typename}</li>
                    {List.map(({ id: msgId, Ftitle: msgTitle }: any) =>
                        <li onClick={() => navigate('/help/' + msgId)} className="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600"><span className={`pl-3 ${id == msgId ? 'text-gray-900' : 'text-gray-400 cursor-pointer'}`}>{msgTitle}</span></li>
                    )}
                </ul>
            )}
        </div>
    )
}