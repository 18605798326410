import { Dropdown, DropdownOptions } from "flowbite";
import { useEffect, useState } from "react"
import { useQuery, useQueryClient } from "react-query";
import { Get, IApiResult, PostData } from "./request";

export const useSearchContext = (inputRef: any, dropdownDomRef: any) => {
    const queryClient = useQueryClient();
    const sKey = 'tipDatas';

    const [dropdown, setDropdown] = useState<any>(null);
    const [selectIndex, setSelectIndex] = useState(-1);
    const [searchVal, setSearchVal] = useState('');

    // options with default values
    const options: DropdownOptions = {
        placement: 'bottom-start',
        triggerType: 'none',// 'click',
        offsetSkidding: 0,
        offsetDistance: 0,
        delay: 20,
        onHide: () => {
            // console.log('dropdown has been hidden')
        },
        onShow: () => {
            // console.log('dropdown has been shown');
        },
        onToggle: () => {
            // console.log('dropdown has been toggled');
        },
    };

    // instance options object
    const instanceOptions = {
        // id: 'dropdownMenu',
        override: true
    };

    const { data: tipDatas, refetch: searchRefetch } = useQuery([sKey], () => PostData({
        url: "/Home/GetSearchTip", jsonData: { key: searchVal }, fail: ({ Code, Message, Result }: IApiResult) => {
        }
    }), { enabled: false });

    useEffect(() => {
        // console.log('selectIndex change');
        try { if (selectIndex > -1) setSearchVal(tipDatas[selectIndex]) } catch { }
    }, [selectIndex]);

    useEffect(() => {
        // console.log('tipDatas change');
        checkDropdownDisplay();
    }, [tipDatas]);

    //init
    useEffect(() => {
        setDropdown(new Dropdown(dropdownDomRef.current, inputRef.current, options, instanceOptions));
    }, [])

    const checkDropdownDisplay = () => {
        // console.log('checkDripDown', (tipDatas ?? []).length)
        try { if ((tipDatas ?? []).length > 0) dropdown.show(); else dropdown.hide(); } catch { }
    }

    const focusHandler = (e: any) => {
        // console.log('inputFocusHandler')
        checkDropdownDisplay();
    }
    const inputHandler = (e: any) => {
        // console.log('inputHandler')
        const val = e.target.value;
        try { queryClient.cancelQueries([sKey]); } catch { }

        setSearchVal(val); setSelectIndex(-1);

        //start search tip
        setTimeout(() => searchRefetch(), 500);
    }
    const keyDownHandler = (e: any) => {
        // console.log('inputKeyDownHandler')
        const { keyCode, key } = e;
        const len = (tipDatas ?? []).length;
        // console.log(len, keyCode, key)
        if (len > 0) {
            if (keyCode === 40) {//按向下键盘
                if (selectIndex == len - 1) setSelectIndex(0)
                else setSelectIndex(Math.min(selectIndex + 1, len - 1));
                e.stopPropagation();
            }
            else if (keyCode === 38) {//按向上键盘
                if (selectIndex == 0) setSelectIndex(len - 1)
                else setSelectIndex(Math.max(selectIndex - 1, 0));
                e.stopPropagation();
            }
        }
    }

    return { dropdown, focusHandler, inputHandler, keyDownHandler, searchVal, setSearchVal, selectIndex, setSelectIndex, tipDatas: tipDatas ?? [] };
}