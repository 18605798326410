import Marquee from "react-easy-marquee";
import { LocalKey, LocalStorage } from "ts-localstorage";

export const BaseUrl = 'https://www.xirou.com';
export const header_pt = 'pt-[60px] md:pt-0';

export const isDevelopment = process.env.NODE_ENV === "development";
export const apiUrl = isDevelopment
  ? "http://localhost:45666"
  // ? "http://192.168.101.11:45666"
  : "https://api2.xirou.com";

export const _spmKey = new LocalKey("_spmKey", "");
export const get_Spm = () => LocalStorage.getItem(_spmKey)
export const set_Spm = (value: string) => LocalStorage.setItem(_spmKey, value)
export const clear_Spm = () => LocalStorage.removeItem(_spmKey)

export const tokenKey = new LocalKey("tokenKey", "");
export const getToken = () => LocalStorage.getItem(tokenKey)
export const setToken = (value: string) => LocalStorage.setItem(tokenKey, value)
export const clearToken = () => LocalStorage.removeItem(tokenKey)

export const isLogin = () => (getToken() ?? '').length > 0;

// 解密JWT Token
export const decodeToken = (): any => {
  try {
    let token = getToken();
    // console.log('token', token)
    if (token) {
      // 解密JWT Token
      let decodeToken = JSON.parse(
        decodeURIComponent(
          atob((token ?? "").split(".")[1].replace("-", "+").replace("_", "/"))
            .split("")
            .map((c) => `%${("00" + c.charCodeAt(0).toString(16)).slice(-2)}`)
            .join("")
        )
      );

      const userdata = JSON.parse(
        decodeURIComponent(
          decodeToken[
          "http://schemas.microsoft.com/ws/2008/06/identity/claims/userdata"
          ])
      );
      const id =
        decodeToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"];
      const role =
        decodeToken["http://schemas.microsoft.com/ws/2008/06/identity/claims/role"];
      const Account = userdata.Account;
      const NickName = userdata.NickName || "匿名";
      const Avatar = userdata.Avatar || "/defaultAvatar.png";
      const Sign = userdata.Sign ?? "";
      const u = { id, role, Account, NickName, Avatar, Sign };
      // console.log(u)
      return u;
    }
  } catch (e) { throw e; }
  return {};
};

export const sleep = (ms: number = 1000) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const ProTags = ({ className, transportType, hzgm, hfgm, reservation, recommend }: any) => {
  // shelves 上架
  let tags = []
  // transportType 运输模式
  if (transportType != '常温运输') tags.push([transportType, 'bg-blue-100 text-blue-800 font-medium me-2 px-1.5 py-0.5 rounded dark:bg-blue-900 dark:text-blue-300']);
  // hzgm 会诊购买
  if (hzgm) tags.push(['会诊购买', 'bg-pink-100 text-pink-800 font-medium me-2 px-1.5 py-0.5 rounded dark:bg-pink-900 dark:text-pink-300']);
  // hfgm 恢复购买
  else if (hfgm) tags.push(['恢复购买', 'bg-yellow-100 text-yellow-800 font-medium me-2 px-1.5 py-0.5 rounded dark:bg-yellow-900 dark:text-yellow-300']);
  // reservation 预订
  else if (reservation) tags.push(['预定购买', 'bg-red-100 text-red-800 font-medium me-2 px-1.5 py-0.5 rounded dark:bg-red-900 dark:text-red-300']);
  // recommend 推荐
  else if (recommend) tags.push(['推荐', 'bg-green-100 text-green-800 font-medium me-2 px-1.5 py-0.5 rounded dark:bg-green-900 dark:text-green-300']);

  if (tags.length == 0) return null;
  return (<div className={className}>{tags.map((tag) => <span className={tag[1]}>{tag[0]}</span>)}</div>);
}

export const ListView = ({ PCItem = false, PCItem2 = false, PCItem3 = false, showRIcon = false, items, proItemClick }: any) => {
  return <>
    {items.map(({ id, brandNameEN, brandName, commonTitleEN, commonTitle, company, manufacturedBy, pics = [''], strength, packing, xirouPrice,
      transportType, shelves, recommend, reservation, hfgm, hzgm
    }: any) => {
      const title = brandNameEN + ' ' + commonTitle;
      const imgPath = `${BaseUrl}${pics[0]}`;

      if (PCItem3) {
        return (
          <li className={`h-1/3 hidden md:block bg-white cursor-pointer`} onClick={(e) => proItemClick({ e, id, imgPath, title })}>
            <a className={`flex items-center p-2`}>
              <div className='p-2'>
                <img className={`w-20 object-covers aspect-square	`} src={imgPath} alt={''} />
              </div>
              <div className='flex flex-col'>
                <h6 className="mb-1 text-xs font-semibold text-gray-900 dark:text-white overflow-ellipsis overflow-hidden line-clamp-1">
                  {title}
                </h6>
                <ul className=" text-xs text-gray-500 dark:text-gray-400 flex-1 flex flex-col">
                  <li>
                    <ul className="flex flex-col">
                      <li>
                        {company}&{manufacturedBy}
                      </li>
                      <li>
                        {strength} {packing}
                      </li>
                    </ul>
                  </li>
                  <li className='flex items-center space-x-1 h-8'>
                    {(hzgm == false && hfgm == false && reservation == false) && <div className="text-[#ff6600] text-lg">￥{xirouPrice}</div>}
                    <ProTags className='text-xs' transportType={transportType} hzgm={hzgm} hfgm={hfgm} reservation={reservation} recommend={recommend} />
                  </li>
                </ul>
              </div>
            </a>
          </li>
        )
      }

      if (PCItem2) {
        return (
          <li className={`flex-1 hidden md:block bg-white cursor-pointer`} onClick={(e) => proItemClick({ e, id, imgPath, title })}>
            <a className={`flex items-center p-2`}>
              <div className='p-2'>
                <img className={`w-20 object-covers aspect-square	`} src={imgPath} alt={''} />
              </div>
              <div className='flex flex-col'>
                <h6 className="mb-1 text-xs font-semibold text-gray-900 dark:text-white overflow-ellipsis overflow-hidden line-clamp-1">
                  {title}
                </h6>
                <ul className=" text-xs text-gray-500 dark:text-gray-400 flex-1 flex flex-col">
                  <li>
                    <ul className="flex flex-col">
                      <li>
                        {company}&{manufacturedBy}
                      </li>
                      <li>
                        {strength} {packing}
                      </li>
                    </ul>
                  </li>
                  <li className='flex items-center space-x-1 h-8'>
                    {(hzgm == false && hfgm == false && reservation == false) && <div className="text-[#ff6600] text-lg">￥{xirouPrice}</div>}
                    <ProTags className='text-xs' transportType={transportType} hzgm={hzgm} hfgm={hfgm} reservation={reservation} recommend={recommend} />
                  </li>
                </ul>
              </div>
            </a>
          </li>
        )
      }

      if (PCItem) {
        return (
          <li className={`flex-1 hidden md:block bg-white cursor-pointer`} onClick={(e) => proItemClick({ e, id, imgPath, title })}>
            <a className={`flex flex-col items-center px-10 py-2`}>
              <div className='p-4'>
                <img className={`w-32 object-covers aspect-square	`} src={imgPath} alt={''} />
              </div>
              <div className='flex flex-col self-stretch'>
                <h6 className="mb-1 text-xs font-semibold text-gray-900 dark:text-white overflow-ellipsis overflow-hidden line-clamp-1">
                  {title}
                </h6>
                <ul className=" text-xs text-gray-500 dark:text-gray-400 flex-1 flex flex-col">
                  <li>
                    <ul className="flex space-x-1 flex-wrap">
                      <li>
                        {company}&{manufacturedBy}
                      </li>
                      <li>
                        {strength} {packing}
                      </li>
                    </ul>
                  </li>
                  <li className='flex-1'></li>
                  <li className='flex items-center space-x-1 h-8'>
                    {(hzgm == false && hfgm == false && reservation == false) && <div className="text-[#ff6600] text-lg">￥{xirouPrice}</div>}
                    <ProTags className='text-xs' transportType={transportType} hzgm={hzgm} hfgm={hfgm} reservation={reservation} recommend={recommend} />
                  </li>
                </ul>
              </div>
            </a>
          </li>
        )
      }

      return (
        <>
          {/* Wap */}
          <li className='md:hidden seven:bg-white odd:bg-gray-50 cursor-pointer' onClick={(e) => proItemClick({ e, id, imgPath, title })}>
            <a className='flex items-center space-x-2 p-2 '>
              <div className='h-24 w-24 border rounded-lg overflow-hidden'>
                <img className='h-24' src={imgPath} alt={''} />
              </div>
              <div className='flex-1 w-1 flex flex-col self-stretch'>
                <h6 className="mb-1 text-sm font-semibold text-gray-900 dark:text-white overflow-ellipsis overflow-hidden whitespace-nowrap">
                  {title}
                </h6>
                <ul className=" text-sm text-gray-500 dark:text-gray-400 flex-1 flex flex-col">
                  <li>
                    {company}&{manufacturedBy}
                  </li>
                  <li>
                    {strength} {packing}
                  </li>
                  <li className='flex-1'></li>
                  <li className='flex items-center space-x-1'>
                    {(hzgm == false && hfgm == false && reservation == false) && <div className="text-[#ff6600] text-lg">￥{xirouPrice}</div>}
                    <ProTags className='text-xs' transportType={transportType} hzgm={hzgm} hfgm={hfgm} reservation={reservation} recommend={recommend} />
                  </li>
                </ul>
              </div>
              {showRIcon && <img className='pointer-events-none h-6' src='https://www.xirou.com/App_Themes/UI/WapImg/sort_ico.png' />}
            </a>
          </li>
          {/* PC List */}
          <li className={`w-1/4 hidden md:block even:bg-white odd:bg-gray-50 cursor-pointer`} onClick={(e) => proItemClick({ e, id, imgPath, title })}>
            <a className={`flex flex-col items-center border hover:border-cyan-800 p-3 m-3`}>
              <div className='p-6'>
                <img className={`w-full object-covers aspect-square`} src={imgPath} alt={''} />
              </div>
              <div className='flex flex-col self-stretch'>
                <h6 className="mb-1 text-sm font-semibold text-gray-900 dark:text-white overflow-ellipsis overflow-hidden line-clamp-2">
                  {title}
                </h6>
                <ul className=" text-sm text-gray-500 dark:text-gray-400 flex-1 flex flex-col">
                  <li>
                    <ul className="flex space-x-1 flex-wrap">
                      <li>
                        {company}&{manufacturedBy}
                      </li>
                      <li>
                        {strength} {packing}
                      </li>
                    </ul>
                  </li>
                  <li className='flex-1'></li>
                  <li className='flex items-center space-x-1 h-8'>
                    {(hzgm == false && hfgm == false && reservation == false) && <div className="text-[#ff6600] text-lg">￥{xirouPrice}</div>}
                    <ProTags className='text-xs' transportType={transportType} hzgm={hzgm} hfgm={hfgm} reservation={reservation} recommend={recommend} />
                  </li>
                </ul>
              </div>
            </a>
          </li>
        </>
      )
    })}</>
}

export const LoadingView = () => {
  return (
    <div className='flex justify-center'>
      <div role="status">
        <svg aria-hidden="true" className="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
          <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
        </svg>
      </div>
    </div>
  )
}
export const NoDataView = () => {
  return (
    <div className="flex justify-center pt-5">
      <span className="text-sm">没有搜索到任何数据</span>
    </div>
  )
}

export const WapFooterTxt = ({ className = 'md:hidden' }: any) => {
  return (
    <p className={`text-xs pt-3 text-gray-400 dark:text-gray-500 flex flex-col items-center ${className}`}>
      <div>Copyright 2015-现在 @ Xirou.com All Rights Reserved </div>
      <div>版权所有：细柔健康 <a href="mailto:info@xirou.net" className="hover:underline">info@xirou.net</a></div>
    </p>
  )
}

export const DengView = ({ align = 'center', width = '100%' }: any) => {
  return (
    <Marquee
      duration={10000}
      height="24px"
      width={width}
      axis="Y"
      align={align}
      reverse={true}
    >
      {/* 倒序播放 */}
      {/* <p style={{ height: '24px' }} className="text-base text-gray-100"></p> */}
      <p style={{ height: '24px' }} className="text-base text-gray-100">药品最大购买量不得超过个人三个月的使用量</p>
      <p style={{ height: '24px' }} className="text-base text-gray-100">购买处方药必须上传处方并经专业审核</p>
      <p style={{ height: '24px' }} className="text-base text-gray-100">根据加拿大法律</p>
      <p style={{ height: '24px' }} className="text-base text-gray-100">加拿大药品跨境自购中文网站</p>
    </Marquee>
  )
}