import { useNavigate } from "react-router-dom";

function NoPage() {
  const navigate = useNavigate();
  return (
    <div className='w-full h-screen flex flex-col justify-center items-center'>
      <p className="mt-1 mx-auto text-gray-500">Page Not Found</p>
      <p className="mt-1 mx-auto text-gray-500 text-2xl">404</p>
      <a onClick={() => navigate('/password', { replace: true })} className=" mt-5 font-medium text-primary-600 dark:text-primary-500 hover:underline">细柔入口</a>
    </div>
  );
}

export default NoPage;
