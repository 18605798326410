import axios, { AxiosError } from "axios";
import { apiUrl, clear_Spm, clearToken, decodeToken, get_Spm, getToken } from "./global";
import qs from "qs";
import { useNotify } from "./useNotify";
import { useNavigate } from "react-router-dom";

interface IRequestParams {
  url: string,
  jsonData: any,
  fail?: (data: IApiResult) => void
  checkLogin?: boolean
}
export interface IApiResult {
  Code: number,
  Message: string,
  Result: any
}

const _response = (p: Promise<any>, fail: (data: IApiResult) => void) => {
  return p
    .then(({ status, data }: any) => {
      if (status != 200) throw new AxiosError(`status={status}`);
      // console.log('_response', JSON.stringify(data))
      return data;
    })
    .then(({ Code, Message, Result }: IApiResult) => {
      if (Code == 1) return Result;
      fail({ Code, Message, Result });
      throw new AxiosError(Message);
    })
    .catch(({ code, response }: AxiosError) => {
      if (code === 'ERR_NETWORK') {
        // alert('网络错误请稍候重试'); 
        window.location.replace('/error' + '?msg=网络错误请稍候重试');
        return;
      }
      const { status } = (response ?? {}) as any;
      console.error('response catch', code, status)
      if (status == 402) {//spm error
        clear_Spm();
        window.location.replace('/password');
      }
      else if (status == 401) {
        // alert('未登陆或登陆已过期');
        clearToken();
        window.location.replace('/login');
      }
      else if (status == 403) {
        alert('无权访问资源');
      }
    })
}

const defaultFail = ({ Code, Message, Result }: IApiResult): void => {
  alert(Message);
  console.warn(Message);
}

const getHearderParam = (checkLogin: boolean) => {
  const token = getToken();
  const spm = get_Spm() ?? "";
  return checkLogin ? { 'Authorization': token, spm } : { spm };
}

export const Get = ({ url, jsonData, checkLogin, fail }: IRequestParams) => {
  const options = getHearderParam(checkLogin ?? true);
  return _response(axios.get(`${apiUrl}${url}?${encodeURI(qs.stringify(jsonData))}`, {
    headers: { ...options }
  }), fail ?? defaultFail)
};

export const PostData = ({ url, jsonData, checkLogin, fail }: IRequestParams) => {
  const options = getHearderParam(checkLogin ?? true);
  return _response(axios.post(apiUrl + url, qs.stringify(jsonData), {
    headers: { "Content-Type": "application/x-www-form-urlencoded", ...options }
  }), fail ?? defaultFail)
};

export const PostJson = ({ url, jsonData, checkLogin, fail }: IRequestParams) => {
  const options = getHearderParam(checkLogin ?? true);
  return _response(axios.post(apiUrl + url, jsonData, {
    headers: { ...options }
  }), fail ?? defaultFail)
};